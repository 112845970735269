export default {
  baseUrlApi: 'https://api-pvffee.giaohangtietkiem.website/api/v1/',
  // baseUrlApi: 'http://45.117.168.142:8124/api/v1/',
  listQuanHe: [
    {
      value: 'bo-me',
      label: 'Bố mẹ',
    },
    {
      value: 'vo-chong',
      label: 'Vợ chồng',
    },
    {
      value: 'anh-chi',
      label: 'Anh chị',
    },
    {
      value: 'dong-nghiep',
      label: 'Đồng nghiệp',
    },
    {
      value: 'ban',
      label: 'Bạn',
    },
  ],
  listStatus: [
    {
      value: 'accepted',
      label: 'Đã xác thực',
    },
    {
      value: 'pending',
      label: 'Chưa xác thực',
    },
  ],
  listStatusContact: [
    {
      value: 'pending',
      label: 'Chờ duyệt',
    },
    {
      value: 'rejected',
      label: 'Đã hủy',
    },
    {
      value: 'accepted',
      label: 'Đang hoạt động',
    },
    {
      value: 'settled',
      label: 'Đã tất toán',
    },
  ],
  listStatusFB: [
    {
      value: true,
      label: 'Đang hoạt động',
    },
    {
      value: false,
      label: 'Khóa',
    },
  ],
  listStatusHome: [
    {
      value: 'co',
      label: 'Có',
    },
    {
      value: 'khong',
      label: 'Không',
    },
  ],
  optionSweetAlert: {
    position: 'top-start',
  },
  configCurrency: {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 0,
    masked: false /* doesn't work with directive */,
  },
}
