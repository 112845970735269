import axios from 'axios'
import DataService from '@/service/DataService'
// import { ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'

window.requestCount = 0

let ApiService = {}

ApiService = async (objData) => {
  window.requestCount += 1

  try {
    const response = await axios({
      method: objData.method,
      url:
        objData.type == 'upload'
          ? objData.url
          : DataService.baseUrlApi + objData.url,
      data: objData.data,
      params: objData.params,
      headers: objData.headers || {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' +
          localStorage.getItem(`web.token[${DataService.baseUrlApi}]`),
      },
    })

    checkSuccess(response, objData)

    return response.data
  } catch (error) {
    checkError(error, objData)

    if (error.response) {
      if (error.response.status == 401) {
        ElNotification({
          message: 'Phiên đăng nhập đã hết hạn',
          type: 'error',
        })
        // ElMessage({
        //   message: 'Phiên đăng nhập đã hết hạn',
        //   type: 'error',
        // })
        localStorage.removeItem(`web.token[${DataService.baseUrlApi}]`)
        localStorage.removeItem(`role`)
        this.$router.push({ name: 'Login' })
        throw error.response.status
      } else {
        throw error.response.data.message
      }
    } else {
      throw error.message
    }
  }
}

function checkSuccess(response, objData) {
  window.requestCount -= 1
  // if (window.requestCount == 0) {
  //   unblockUi()
  // }
  if (
    objData.message &&
    objData.message.success &&
    objData.message.success == 'hidden'
  ) {
    return
  }
  if (
    objData.message &&
    objData.message.success &&
    objData.message.success != 'hidden'
  ) {
    ElNotification({
      message: objData.message.success,
      type: 'success',
    })
    // ElMessage({
    //   message: objData.message.success,
    //   type: 'success',
    // })
  } else {
    if (objData.type == 'create') {
      ElNotification({
        message: 'Thêm mới thành công',
        type: 'success',
      })
    } else if (objData.type == 'partialUpdate') {
      ElNotification({
        message: 'Cập nhật thành công',
        type: 'success',
      })
    } else if (objData.type == 'delete') {
      ElNotification({
        message: 'Xóa thành công',
        type: 'success',
      })
    }
  }
}

function checkError(error, objData) {
  window.requestCount -= 1
  // if (window.requestCount == 0) {
  //   unblockUi()
  // }
  if (
    objData.message &&
    objData.message.error &&
    objData.message.error == 'hidden'
  ) {
    return
  }
  if (
    objData.message &&
    objData.message.error &&
    objData.message.error != 'hidden'
  ) {
    ElNotification({
      message: objData.message.error,
      type: 'error',
    })
    // ElMessage({
    //   message: objData.message.error,
    //   type: 'error',
    // })
  } else {
    if (objData.type == 'create') {
      ElNotification({
        message: 'Thêm mới thất bại',
        type: 'error',
      })
    } else if (objData.type == 'partialUpdate') {
      ElNotification({
        message: 'Xóa thất bại',
        type: 'error',
      })
    } else if (objData.type == 'delete') {
      ElNotification({
        message: 'Xóa thất bại',
        type: 'error',
      })
    }
  }
}

export default ApiService
