export default [
  {
    component: 'CNavTitle',
    name: 'Quản lý đơn vay',
  },
  {
    component: 'CNavItem',
    name: 'Duyệt đơn vay mới',
    to: '/loan',
    icon: 'cil-file',
  },
  {
    component: 'CNavItem',
    name: 'Đơn đang hoạt động',
    to: '/loan-active',
    icon: 'cil-description',
  },
  {
    component: 'CNavTitle',
    name: 'Quản lý khách hàng',
  },
  {
    component: 'CNavItem',
    name: 'Danh sách khách hàng',
    to: '/customer',
    icon: 'cil-address-book',
  },
  {
    component: 'CNavTitle',
    name: 'Quản lý FB hỗ trợ',
  },
  {
    component: 'CNavItem',
    name: 'Danh sách FB',
    to: '/support',
    icon: 'cil-comment-bubble',
  },
  {
    component: 'CNavTitle',
    name: 'Quản lý khác',
  },
  {
    component: 'CNavItem',
    name: 'Quản lý nội dung',
    to: '/manage-content',
    icon: 'cil-notes',
  },
]
