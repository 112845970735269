import { h, resolveComponent } from 'vue'
import { createRouter } from 'vue-router'
import { createWebHashHistory } from 'vue-router'
// import { createWebHistory } from 'vue-router'
// import { createMemoryHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/loan',
    children: [
      {
        path: '/loan',
        name: 'Loan',
        redirect: '/loan',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'LoanList',
            component: () => import('@/views/loan/new-loan/NewLoanList'),
          },
          {
            path: 'detail/:id',
            name: 'LoanForm',
            component: () => import('@/views/loan/new-loan/LoanForm'),
          },
        ],
      },
      {
        path: '/loan-active',
        name: 'ActiveLoan',
        redirect: '/loan-active',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'ActiveLoanList',
            component: () => import('@/views/loan/active-loan/ActiveLoanList'),
          },
          {
            path: 'detail/:id',
            name: 'ActiveLoanForm',
            component: () => import('@/views/loan/active-loan/ActiveLoanForm'),
          },
        ],
      },
      {
        path: '/customer',
        name: 'Customer',
        redirect: '/customer',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'CustomerList',
            component: () => import('@/views/customer/CustomerList'),
          },
          {
            path: 'detail/:id',
            name: 'CustomerForm',
            component: () => import('@/views/customer/CustomerForm'),
          },
        ],
      },
      {
        path: '/support',
        name: 'Support',
        redirect: '/support',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'SupportList',
            component: () => import('@/views/support/SupportList'),
          },
          {
            path: 'create',
            name: 'SupportFormCreate',
            component: () => import('@/views/support/SupportForm'),
          },
          {
            path: 'update/:id',
            name: 'SupportFormUpdate',
            component: () => import('@/views/support/SupportForm'),
          },
        ],
      },
      {
        path: '/manage-content',
        name: 'ManageContent',
        component: () => import('@/views/manage-content/ManageContent'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    redirect: '/auth/login',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/auth/Register'),
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => import('@/views/auth/ChangePassword'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  // history: createMemoryHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

import DataService from '@/service/DataService'
import { ElMessage } from 'element-plus'
router.beforeEach((to, from, next) => {
  if (window.setTimeoutTablePending) {
    clearTimeout(window.setTimeoutTablePending)
  }
  if (window.setTimeoutTableCanceled) {
    clearTimeout(window.setTimeoutTableCanceled)
  }
  if (window.setTimeoutTableTatToan) {
    clearTimeout(window.setTimeoutTableTatToan)
  }
  if (window.setTimeoutTableActive) {
    clearTimeout(window.setTimeoutTableActive)
  }
  if (window.setTimeoutCount) {
    clearTimeout(window.setTimeoutCount)
  }
  //ĐAnh sách các route không cần check login
  const publicPages = ['/auth/login', '/auth/register']
  const authRequired = !publicPages.includes(to.path)
  //Check có token hay không
  const token = localStorage.getItem(`web.token[${DataService.baseUrlApi}]`)
  if (token && authRequired) {
    //Nếu có token thì cho qua
    next()
  } else {
    //Nếu không có token thì chuyển về trang login
    if (to.name !== 'Login') {
      ElMessage.error('Bạn chưa đăng nhập')
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})

export default router
