import DataService from '@/service/DataService'
import ApiService from '@/service/ApiService'

window.requestCount = 0

let AuthService = {}

//Đăng nhập
AuthService.login = async (objData) => {
  let api = {
    method: 'POST',
    url: 'auth/login',
    data: objData,
    message: {
      success: 'hidden',
      error: 'hidden',
    },
  }
  const response = await ApiService(api)
  //Thực hiện lưu vào localstorage
  localStorage.setItem(
    `web.token[${DataService.baseUrlApi}]`,
    response.access_token,
  )
  localStorage.setItem(`role`, response.role)
  return response
}

//Đăng xuất
AuthService.logout = async () => {
  //Thực hiện xóa localstorage
  try {
    localStorage.removeItem(`web.token[${DataService.baseUrlApi}]`)
    localStorage.removeItem(`role`)
    return true
  } catch (error) {
    return false
  }
}

//Đổi mat khau
AuthService.changePassword = async (objData) => {
  let api = {
    method: 'PUT',
    url: 'auth/password',
    data: objData,
    message: {
      success: 'Đôi mật khẩu thành công',
      error: 'Đôi mật khẩu thất bại',
    },
  }
  const response = await ApiService(api)
  return response
}

//Đổi mat khau cá nhân
AuthService.changeMyPassword = async (objData) => {
  let api = {
    method: 'PUT',
    url: 'auth/my-password',
    data: objData,
    message: {
      success: 'Đôi mật khẩu thành công',
      error: 'Đôi mật khẩu thất bại',
    },
  }
  const response = await ApiService(api)
  return response
}

export default AuthService
