import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { BootstrapVue } from 'bootstrap-vue'
// import { IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import {
  faPhone,
  faUserSecret,
  faPenToSquare,
  faTrash,
  faRightFromBracket,
  faUnlockKeyhole,
  faUpload,
  faTrashCan,
  faUpDownLeftRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faPhone,
  faUserSecret,
  faPenToSquare,
  faTrash,
  faRightFromBracket,
  faUnlockKeyhole,
  faUpload,
  faTrashCan,
  faUpDownLeftRight,
)

window.axios = require('axios')
window.toastr = require('toastr')
window.toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '20000',
  timeOut: '20000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// import viLocale from 'element-plus/lib/locale/lang/vi'

const app = createApp(App)
app.use(ElementPlus)
// app.use(ElementPlus, { locale: viLocale })
app.use(BootstrapVue3)
app.use(VueSweetalert2)
app.use(store)
app.use(router)
app.use(CoreuiVue)
// app.use(BootstrapVue)
// app.use(IconsPlugin)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
