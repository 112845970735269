import ApiService from '@/service/ApiService'
let UserApi = {}
let moduleName = 'users'

UserApi.getUserProfile = async () => {
  let api = {
    method: 'GET',
    url: `${moduleName}/profile`,
    message: {
      success: 'hidden',
      error: 'hidden',
    },
  }
  const response = await ApiService(api)
  return response
}

UserApi.getAllUser = async (objData) => {
  let api = {
    method: 'GET',
    url: `${moduleName}`,
    params: objData,
    message: {
      success: 'hidden',
      error: 'hidden',
    },
  }
  const response = await ApiService(api)
  return response
}

UserApi.getCustomerData = async (id) => {
  let api = {
    method: 'GET',
    url: `${moduleName}/${id}`,
    message: {
      success: 'hidden',
      error: 'hidden',
    },
  }
  const response = await ApiService(api)
  return response
}

UserApi.updateCustomerData = async (id, objData, message) => {
  console.log('id', id)
  let api = {
    method: 'PUT',
    url: `${moduleName}/${id}`,
    data: objData,
    message: message
      ? message
      : {
          success: 'Cập nhật thông tin thành công',
          error: 'Cập nhật thông tin thất bại',
        },
  }
  const response = await ApiService(api)
  return response
}

export default UserApi
