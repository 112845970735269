<template>
  <el-dropdown trigger="click">
    <div class="drop-down__block" v-if="!loading">
      <el-avatar :size="40" src="/img/admin.png" />
      <p>Admin</p>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="fn_changePass">
          <font-awesome-icon
            icon="fa-solid fa-unlock-keyhole"
            class="drop-down__icon"
          />
          Đổi mật khẩu
        </el-dropdown-item>
        <el-dropdown-item @click="fn_logOut">
          <font-awesome-icon
            icon="fa-solid fa-right-from-bracket"
            class="drop-down__icon"
          />
          Đăng xuất
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import AuthService from '@/service/AuthService'
import UserApi from '@/api/UserApi'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      itemsCount: 42,
      profile: {
        avatar: undefined,
      },
      loading: false,
    }
  },
  methods: {
    fn_logOut() {
      ElMessageBox.confirm('Bạn có chắc chắn muốn đăng xuất?', 'Đăng xuất', {
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning',
        draggable: true,
      })
        .then(() => {
          AuthService.logout().then((value) => {
            if (value) {
              ElMessage({
                type: 'success',
                message: 'Đăng xuất thành công!',
              })
              this.$router.push({ name: 'Login' })
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'warning',
            message: 'Đăng xuất thất bại!',
          })
        })
    },
    fn_changePass() {
      console.log('Đổi mật khẩu nè')
      this.$router.push({ name: 'ChangePassword' })
    },
    async getUserProfile() {
      const resProfileApi = await UserApi.getUserProfile()
      if (resProfileApi) {
        this.profile = resProfileApi.data
        console.log('this.profile', this.profile)
      }
    },
  },
  created() {
    this.getUserProfile()
  },
}
</script>
<style lang="scss" scoped>
:deep(.drop-down__block) {
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
  }
}
:deep(.drop-down__icon) {
  margin-right: 10px;
}
</style>
